import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Table } from 'antd';
import banVen from '../../resourses/assets/images/banVen.png';
import banCol from '../../resourses/assets/images/banCol.png';

import './TablePesos.scss';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const TabsCountrys = ({ useStyles, dataSource, columnsOfColombia, columnsOfVenezuela }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  const classes = useStyles();

  return (
    <>
      <AppBar position="static" color="inherit" className={classes.tabPanel}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="secondary"
          centered
        >
          <Tab
            label="Colombia"
            icon={<img alt="Bandera Colombia" src={banCol} />}
            {...a11yProps(0)}
          />
          <Tab
            label="Venezuela"
            icon={<img alt="Bandera Venezuela" src={banVen} />}
            {...a11yProps(1)}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Table className="TablePesos" dataSource={dataSource} columns={columnsOfColombia} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Table className="TablePesos" dataSource={dataSource} columns={columnsOfVenezuela} />
      </TabPanel>
    </>
  );
};

export default TabsCountrys;
