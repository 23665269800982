import React from 'react';

import { Grid, Typography } from '@material-ui/core';
import { LayoutConde } from '../../components';
import { BackgroundEntidad } from '../../components/BackGroundMultimedia/index';
import Bancos from './Bancos';

const ContactComponent = ({ data, useStyles }) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.root}>
      <BackgroundEntidad className={classes.backgroundmultimedia} />
      <Typography variant="h2" component="p" className={classes.titleHead}>
        Entidades
      </Typography>
      <Typography variant="body2" component="p" className={classes.description}>
        Esta es la variedad de entidades con las cuales te facilitamos la tarea de hacer cambios,
        nuestro horario de atencion es de 07:00 a.m a 09:00 pm.
        <br /> Siempre podrás ayudar a tu familia con nosotros.
      </Typography>
      <Bancos useStyles={useStyles} data={data} />
    </Grid>
  );
};

const Entidades = () => {
  return <LayoutConde componente={ContactComponent} />;
};

export default Entidades;
