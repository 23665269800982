import { makeStyles } from '@material-ui/core/styles';

// Colors
const primaryColor = '#212121';
const secondaryColor = '#30336b';
const linkColor = '#C5CAE9';
const linkActiveColor = '#ff8383';
const marginAuto = '0.5em auto';

const textDecoration = 'none !important';
const backgroundColor = '#130f40 !important';
const border = `1px solid ${backgroundColor}`;
const flagVen = 'linear-gradient(yellow 33%, blue 33%, blue 66.66%, red 66.66%)';
const flagCol = 'linear-gradient(yellow 50%, blue 33%, blue 72.66%, red 0%)';
const drawerWidth = 240;

export default makeStyles((theme) => ({
  root: {
    color: primaryColor,
    display: 'flex',
    ////fontFamily: 'Roboto',
    fontFamily: 'IBM Plex Sans',
    backgroundColor: '#ffffff90',
    padding: theme.spacing(2),
  },
  // CONTENT
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      backgroundColor,
    },
    backgroundColor,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
    alignItems: 'center',
    width: 'fit-content',
    color: '#C5CAE9',
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor,
    boxShadow: 'inset -1px 2px 9px 1px #BDBDBD',
  },
  content: {
    minHeight: '100vh',
    flexGrow: 1,
    // padding: theme.spacing(1),
    // maxWidth: '100vw',
    // backgroundColor: '#ffffff90',
  },

  // HEADER_BAR
  link: {
    color: linkColor,
    //fontFamily: 'Roboto',
    fontFamily: 'IBM Plex Sans',
    fontSize: '1rem',
    fontWeight: 'bold',
    letterSpacing: '0.00938em',
    lineHeight: '1.5',
    margin: marginAuto,
    textDecoration: 'none !important',
    width: 'max-content',
    '&:hover': {
      color: linkActiveColor,
      textTransform: 'uppercase',
    },
  },
  linkActive: {
    color: linkActiveColor,
    margin: marginAuto,
    textDecoration,
    textTransform: 'uppercase',
    '&:hover': {
      color: linkActiveColor,
    },
  },
  // +++ SCENES +++
  titleHead: {
    color: primaryColor,
    margin: '0.5em auto',
    justifyContent: 'center',
  },
  // About
  title: {
    color: primaryColor,
    margin: '0 auto',
  },
  titleJustify: {
    color: primaryColor,
    textAlign: 'left',
  },
  subTitle: {
    color: secondaryColor,
    margin: '0 auto',
  },
  contact: {
    '&:hover': {
      color: linkActiveColor,
    },
  },
  social: {
    margin: '0 1rem',
    transition: 'transform 250ms',
    display: 'inline-block',
    '&:hover': {
      color: linkActiveColor,
      transform: 'translateY(-2px)',
    },
  },
  description: {
    color: primaryColor,
    fontSize: '1.2em',
    textAlign: 'justify',
    padding: '0.5em',
  },
  descriptionFooter: {
    color: primaryColor,
    fontSize: '1em',
    textAlign: 'center',
    padding: '0.5em',
  },
  namesClients: {
    color: primaryColor,
    margin: '0 auto',
    fontSize: '1.5em',
    textTransform: 'uppercase',
  },
  // BancosVenezuela
  containerImageBancos: {
    width: '30%',
    height: 100,
    margin: '0.5em auto',
  },
  entidadContaner: {
    textAlign: 'center',
  },
  imgBancos: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  flagVenezuela: {
    width: '50%',
    height: '2em',
    margin: '20px',
    display: 'inline-block',
    border,
    backgroundImage: flagVen,
  },
  flagColombia: {
    width: '50%',
    height: '2em',
    margin: '20px',
    display: 'inline-block',
    border,
    backgroundImage: flagCol,
  },
  starts: {
    color: 'white',
    letterSpacing: '0.5em',
    fontSize: '0.8em',
    margin: '0 auto',
  },
  avatar: {
    width: '50% !important',
    height: '50% !important',
    maxWidth: '50% !important',
  },
  boxClients: {
    padding: theme.spacing(2),
    color: secondaryColor,
    margin: '2em auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'justify',
  },
  // Backgroundmultimedia
  backgroundmultimedia: {
    height: '100vh',
    left: '50%',
    minHeight: '100%',
    minWidth: '100%',
    objectFit: 'cover',
    position: 'fixed',
    top: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    width: '100vw',
    zIndex: '-1',
    opacity: '0.5',
    filter: 'alpha(opacity=50)',
  },
  backTop: {
    height: 40,
    width: 40,
    lineHeight: '40px',
    borderRadius: 50,
    backgroundColor: '#3033695',
    color: linkActiveColor,
    textAlign: 'center',
    fontSize: 14,
  },
  tabPanel: {
    width: 'auto',
    backgroundColor: 'transparent',
  },
}));
