import React from 'react';

import ReactPlayer from 'react-player';

import '../../../resourses/styles/videoBackgound.scss';

const Video = ({ url, speed = 2 }) => {
  return (
    <div className="video-background">
      <div className="video-foreground">
        <ReactPlayer url={url} playing loop playbackRate={speed} />
      </div>
    </div>
  );
};

export default Video;
