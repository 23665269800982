import React from 'react';
import { NavLink } from 'react-router-dom';
import { ListItem, ListItemText } from '@material-ui/core';

import {
  dataMenuLinks,
  bancosVenezuela,
  bancosColombia,
  socialMedia,
} from '../../Data/index';

import useStyles from '../../resourses/styles/Styles';
import Logo from '../Logo';
import ResponsiveDrawer from './ResponsiveDrawer';

const LayoutConde = ({ componente, isLoading, contacts }) => {
  const classes = useStyles();
  const data = { dataMenuLinks, bancosVenezuela, bancosColombia, socialMedia };
  const menuLink = data.dataMenuLinks.map((item) => {
    return (
      <ListItem key={item.id}>
        <NavLink to={item.link} className={classes.link} activeClassName={classes.linkActive}>
          <ListItemText primary={item.text} />
        </NavLink>
      </ListItem>
    );
  });

  return (
    <ResponsiveDrawer
      componente={componente}
      data={data}
      isLoading={isLoading}
      contacts={contacts}
      menuLink={menuLink}
      useStyles={useStyles}
      Logo={Logo}
    />
  );
};

export default LayoutConde;
