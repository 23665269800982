/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Grid, Typography, Link } from '@material-ui/core';
import { Tag } from 'antd';
import { LayoutConde, TablePesos, Loading } from '../../components';

import { BackgroundTableChanges } from '../../components/BackGroundMultimedia/index';

const TableComponent = ({ data, useStyles }) => {
  const [loading, setLoading] = useState(true);

  const [timeStamp, setTimeStamp] = useState({});
  const [USD, setUSD] = useState({});
  const [USDCOP, setUSDCOP] = useState({});

  useEffect(() => {
    if (loading) {
      const fetchData = async () => {
        const result = await axios(process.env.REACT_APP_DOLLAR_TODAY);
        const result2 = await axios(process.env.REACT_APP_FRONT_CLOUD);
        // eslint-disable-next-line no-underscore-dangle
        setTimeStamp(result.data._timestamp);
        setUSD(result.data.USD);
        setUSDCOP(result2.data.USDCOP);
        setLoading(false);
      };
      fetchData();
    }
  }, [loading]);

  const classes = useStyles();

  const { fecha_nice, fecha } = timeStamp;
  const { dolartoday } = USD;
  const { trm } = USDCOP;

  return (
    <>
      <Grid container className={classes.root}>
        <BackgroundTableChanges className={classes.backgroundmultimedia} />
        <Typography variant="h2" component="p" className={classes.titleHead}>
          Tabla de cambios <Tag color="magenta">ultima actualización {fecha} hora Vzla.</Tag>
        </Typography>
        <Typography variant="body2" component="p" className={classes.description}>
          Fecha {fecha_nice || '...'}, te invitamos a verificar las tablas de conversiones.
        </Typography>
        <Typography variant="body2" component="p" className={classes.description}>
          Las referencia del precio Dollar Venezuela tomada de
          <Link
            className={classes.description}
            color="inherit"
            href="https://dolartoday.com"
            rel="noopener noreferrer"
            target="_blank"
            underline="none"
            variant="subtitle1"
          >
            DollarToday
          </Link>
          <Tag color="volcano">{dolartoday || '...'}</Tag>
        </Typography>
        <Typography variant="body2" component="p" className={classes.description}>
          Las referencia del precio Dollar Colombia tomada de
          <Link
            className={classes.description}
            color="inherit"
            href="http://bolivarcucuta.com"
            rel="noopener noreferrer"
            target="_blank"
            underline="none"
            variant="subtitle1"
          >
            Bolivar Cucuta
          </Link>
          <Tag color="purple">{trm || '...'}</Tag>
        </Typography>
      </Grid>
      <Grid container direction="column" justify="center" alignItems="center">
        {loading ? (
          <Loading tip="Calculando Tasa de cambio...." />
        ) : (
          <TablePesos
            data={data}
            dollarVenezuela={dolartoday}
            dollarColombia={trm}
            timeStamp={timeStamp}
            useStyles={useStyles}
          />
        )}
      </Grid>
    </>
  );
};

const Tabla = () => {
  return <LayoutConde componente={TableComponent} />;
};

export default Tabla;
