import axios from 'axios';

import { notification } from 'antd';

import { api } from '../config';

const endpoint = 'contacts';

export async function getContact() {
  try {
    const response = await axios({
      url: `${api}/${endpoint}`,
      method: 'GET',
    });
    return response;
  } catch (error) {
    return notification.error({
      message: 'Error',
      description: error.response ? error.response.data.message : 'Ocurrió un error',
    });
  }
}

export async function saveContact(data) {
  console.log(data);
  // const { name, lastName, email, phone, country, message, image, clausula } = data;
  try {
    // METHOD OF MONGO
    // const formData = new FormData();
    // const asingData = (key, value) => formData.append(key, value);
    // asingData('name', name);
    // asingData('lastName', lastName);
    // asingData('email', email);
    // asingData('phone', phone);
    // asingData('country', country);
    // asingData('message', message);
    // asingData('image', image[0]);
    // asingData('clausula', clausula);

    const response = await axios({
      url: `${api}/${endpoint}`,
      method: 'POST',
      data: data,
    });
    return response;
  } catch (error) {
    return notification.error({
      message: 'Error',
      description: error.response ? error.response.data.message : 'Ocurrió un error',
    });
  }
}
