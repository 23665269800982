import React from 'react';

import { Box, Grid, Typography, Link } from '@material-ui/core';

const start = '★★★★★★★★';

export default function Bancos({ data, useStyles }) {
  const classes = useStyles();

  const CardGridBancoVen = data.bancosVenezuela.map((item) => {
    return (
      <Grid container spacing={3} key={item.id}>
        <Grid item xs>
          <Link
            className={classes.description}
            color="inherit"
            href={item.url}
            rel="noopener noreferrer"
            target="_blank"
            underline="none"
            variant="subtitle1"
          >
            {item.name}
          </Link>
          <Box className={classes.containerImageBancos}>
            <Link href={item.url} target="_blank" rel="noopener noreferrer">
              <img className={classes.imgBancos} alt={item.name} src={item.img} />
            </Link>
          </Box>
        </Grid>
      </Grid>
    );
  });

  const CardGridBancoCol = data.bancosColombia.map((item) => {
    return (
      <Grid container spacing={3} key={item.id}>
        <Grid item xs>
          <Link
            className={classes.description}
            color="inherit"
            href={item.url}
            rel="noopener noreferrer"
            target="_blank"
            underline="none"
            variant="subtitle1"
          >
            {item.name}
          </Link>
          <Box className={classes.containerImageBancos}>
            <Link href={item.url} target="_blank" rel="noopener noreferrer">
              <img className={classes.imgBancos} alt={item.name} src={item.img} />
            </Link>
          </Box>
        </Grid>
      </Grid>
    );
  });

  const cardPais = (pais, card, box) => {
    return (
      <Grid item xs={12} sm={6} className={classes.entidadContaner}>
        <Typography variant="h4" className={classes.title}>
          {pais}
        </Typography>
        {box}
        {card}
      </Grid>
    );
  };

  return (
    <Grid container spacing={3}>
      {cardPais(
        'Venezuela',
        CardGridBancoVen,
        <Box className={classes.flagVenezuela}>
          <span role="img" aria-label="emoji" className={classes.starts}>
            <span>{start}</span>
          </span>
        </Box>,
      )}
      {cardPais('Colombia', CardGridBancoCol, <Box className={classes.flagColombia} />)}
    </Grid>
  );
}
