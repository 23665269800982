import React, { useState } from 'react';

import { Grid, Typography, Link } from '@material-ui/core';
import { Form, Input, Checkbox, Button, AutoComplete, message, Select } from 'antd';

import { saveContact } from '../../services';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { LayoutConde, Clausula } from '../../components';
import { BackgroundContact } from '../../components/BackGroundMultimedia/index';

import './Contact.scss';

const { TextArea } = Input;
const { Option } = Select;

const ContactComponent = ({ useStyles }) => {
  const classes = useStyles();
  const [form] = Form.useForm();
  const [autoCompleteResult, setAutoCompleteResult] = useState([]);
  const [formLayout] = useState('horizontal');

  // const inputFileRef = useRef();

  // function resetFile() {
  //   const file = document.getElementById('file-input');
  //   file.value = '';
  // }

  // METOD OF MONGO
  // const onFinish = (values) => {
  //   const data = { ...values, image: inputFileRef.current.files };
  //   saveContact(data);
  //   message.success('Registado con éxito');
  //   resetFile();
  //   form.resetFields();
  // };

  const onFinish = (values) => {
    console.log(values);
    saveContact(values);
    message.success('Registado con éxito');
    form.resetFields();
  };

  const onEmailChange = (value) => {
    if (!value) {
      setAutoCompleteResult([]);
    } else {
      setAutoCompleteResult(['@gmail.com', '@hotmail.com'].map((domain) => `${value}${domain}`));
    }
  };

  const emailOptions = autoCompleteResult.map((email) => ({
    label: email,
    value: email,
  }));

  const formItemLayout = () => {
    return (
      formLayout === 'horizontal' && {
        labelCol: { span: 10 },
        wrapperCol: { span: 14 },
      }
    );
  };

  const inputGenerator = (label, fieldName, type) => {
    return (
      <Form.Item
        hasFeedback
        label={label}
        name={fieldName}
        rules={[
          { required: true, message: `Por favor ingrese su ${label}` },
          {
            type: type,
            message: 'Correo no Válido!',
          },
        ]}
        size="large"
      >
        {fieldName === 'email' ? (
          <AutoComplete options={emailOptions} onChange={onEmailChange} placeholder={label}>
            <Input allowClear />
          </AutoComplete>
        ) : (
          <Input placeholder={label} allowClear />
        )}
      </Form.Item>
    );
  };

  return (
    <Grid container className={classes.root}>
      <BackgroundContact className={classes.backgroundmultimedia} />
      <Typography variant="h2" component="p" className={classes.titleHead}>
        Contáctenos
      </Typography>
      <Grid
        container
        spacing={3}
        direction="column"
        justify="center"
        alignItems="center"
        className="Contact"
      >
        <Grid item xs>
          <Typography variant="body2" component="p" className={classes.description}>
            Puedes comuniarte con nosotros por este medio, estaremos pendiente si nos llega algun
            correo.
          </Typography>
          <Typography variant="body2" component="p" className={classes.description}>
            {'O Puedes comunicarte por whatsapp '}
            <Link target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_WHATSSAP}>
              <FontAwesomeIcon
                icon={faWhatsapp}
                size="2x"
                color="#51c85d"
                title="Escríbenos 573103178526"
                className={classes.contact}
              />
            </Link>
          </Typography>
        </Grid>

        <Form
          {...formItemLayout}
          form={form}
          name="register"
          onFinish={onFinish}
          scrollToFirstError
        >
          {inputGenerator('Nombre', 'name')}
          {inputGenerator('Apellido', 'lastName')}
          {inputGenerator('Correo', 'email', 'email')}
          {inputGenerator('Télefono', 'phone')}
          <Form.Item
            name="country"
            label="País"
            hasFeedback
            rules={[{ required: true, message: 'Por seleccionar tu País!' }]}
          >
            <Select placeholder="Seleccionar">
              <Option value="Colombia">Colombia</Option>
              <Option value="Venezuela">Venezuela</Option>
            </Select>
          </Form.Item>
          <Form.Item
            hasFeedback
            label="Mensaje"
            name="message"
            rules={[{ required: true, message: 'Por favor escribe tu Mensaje' }]}
          >
            <TextArea
              allowClear
              autoSize={{ minRows: 3, maxRows: 5 }}
              placeholder="Menciona en este mensaje tus inquietudes..."
            />
          </Form.Item>

          {/* <span>Por favor subir una foto de tu identificación</span>
          <br />
          <input type="file" ref={inputFileRef} id="file-input" /> */}

          <Form.Item
            hasFeedback
            name="clause"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(new TypeError('Debe aceptar que leyo la cláusula.')),
              },
            ]}
          >
            <Checkbox>
              He leído la
              <Clausula />
            </Checkbox>
          </Form.Item>
          <Form.Item hasFeedback>
            <Button type="primary" htmlType="submit">
              Enviar
            </Button>
          </Form.Item>
        </Form>
      </Grid>
    </Grid>
  );
};

const Contact = () => {
  return <LayoutConde componente={ContactComponent} />;
};

export default Contact;
