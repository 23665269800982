import React from 'react';

import { Grid, Typography } from '@material-ui/core';

import { LayoutConde, Video } from '../../components';

const AboutComponent = ({ useStyles }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <Typography variant="h2" component="p" className={classes.titleHead}>
        Acerca de Nosotros
      </Typography>
      <Typography variant="body2" component="p" className={classes.description}>
        <span style={{ textTransform: 'uppercase' }}>Q</span>ueremos brindarte la oportunidad de
        tener a la mano alguien que te ayude a gestionar tus remesas, por que sabemos que deseas
        ayudar a los tuyos, por eso estamos aquí contigo.
      </Typography>

      <Typography variant="body2" component="p" className={classes.description}>
        <Typography variant="h4" className={classes.titleJustify}>
          Misión
        </Typography>
        Estamos dedicados a satisfacer competitivamente las necesidades y expectativas de nuestros
        clientes, facilitando la gestiones por medio transferencias, logrando bienestar integral
        para nuestros clientes. Te esperamos y te daremos una excelente atención.
      </Typography>

      <Typography variant="body2" component="p" className={classes.description}>
        <Typography variant="h4" className={classes.titleJustify}>
          Visión
        </Typography>
        Facilitar la manera en como hacer llegar una ayuda a un familiar en el mercado
        colombo-venezolano de manera profesional, con experiencia, solidez y respaldo para brindar
        al cliente la mayor satisfacción, garantía y seguridad.
      </Typography>

      <Video url="https://www.youtube.com/watch?v=K37dsok3drA" speed={1.5} />
    </Grid>
  );
};

const About = () => {
  return <LayoutConde componente={AboutComponent} />;
};

export default About;
