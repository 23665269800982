import React from 'react';
import { NavLink } from 'react-router-dom';

import { Form, Input, Button } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { LayoutConde } from '../../components';

import './Log.scss';

const LoginForm = ({ useStyles }) => {
  const classes = useStyles();

  const onFinish = (values) => {
    console.log('Received values of form: ', values);
  };

  return (
    <Form
      name="normal_login"
      className="login-form"
      initialValues={{ remember: true }}
      onFinish={onFinish}
    >
      <Form.Item
        name="username"
        rules={[{ required: true, message: 'Please input your Username!' }]}
      >
        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[{ required: true, message: 'Please input your Password!' }]}
      >
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Password"
        />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" className="login-form-button">
          Iniciar sesión
        </Button>
        <NavLink to="/clients" className={classes.link} activeClassName={classes.linkActive}>
          Registarme
        </NavLink>
      </Form.Item>
    </Form>
  );
};

const Log = () => {
  return <LayoutConde componente={LoginForm} />;
};

export default Log;
