import React from 'react';
import { Grid, Link, Typography, Divider } from '@material-ui/core';
import { Tag } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { socialMedia } from '../../../Data';

export default function SocialFollow({ useStyles }) {
  const classes = useStyles();
  const socialMediaMenu = socialMedia.map(({ id, href, icon, size, color }) => {
    return (
      <Link
        key={id}
        target="_blank"
        rel="noopener noreferrer"
        href={href}
        className={classes.social}
      >
        <FontAwesomeIcon icon={icon} size={size} color={color} />
      </Link>
    );
  });

  return (
    <Grid item xs className={classes.descriptionFooter}>
      <div className={classes.toolbar} />
      {socialMediaMenu}
      <Typography variant="body2" component="p" className={classes.description}>
        Comparte la web <Tag color="magenta">•El Conde• </Tag>en las redes sociales, nos manda un
        capture y tendrás una recompensa en tu próxima transferencia.
      </Typography>
      <Typography variant="body2" component="p">
        El Conde • ®2020 Todos los derechos reservados •
      </Typography>
      <Divider />
      <Typography>
        <Tag color="darkBlue">Desarrollado por: egrojeseDev</Tag>
      </Typography>
    </Grid>
  );
}
