import React from 'react';

import { Grid } from '@material-ui/core';
import './Logo.scss';

import LogoConde from '../../resourses/assets/images/logo.png';

const Logo = (props) => {
  const { display, height, margin, position, width } = props;

  return (
    <Grid>
      <img
        src={LogoConde}
        alt="LogoConde"
        style={{
          display: display || 'flex',
          height: height || '100%',
          margin: margin || '1%',
          position: position || 'relative',
          width: width || '100%',
          maxWidth: '50%',
        }}
      />
    </Grid>
  );
};

export default Logo;
