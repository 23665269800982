import React from 'react';
import { AppBar, Drawer, Hidden, List, Toolbar, IconButton, Grid } from '@material-ui/core/';
import { BackTop } from 'antd';
import MenuIcon from '@material-ui/icons/Menu';
import { useTheme } from '@material-ui/core/styles';
import Footer from '../Footer';

function ResponsiveDrawer(props) {
  const { useStyles, window, componente, data, isLoading, contacts, menuLink, Logo } = props;
  const Componente = componente;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = () => {
    return (
      <>
        <Logo width="50%" margin="0.5em auto" />
        <List>{menuLink}</List>
      </>
    );
  };

  const container = window !== undefined ? () => window().document.body : undefined;
  return (
    <div className={classes.root}>
      <Hidden smUp>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Logo width="20%" margin="0 auto" />
          </Toolbar>
        </AppBar>
      </Hidden>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp>
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{ paper: classes.drawerPaper }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer({ menuLink })}
          </Drawer>
        </Hidden>
        <Hidden xsDown>
          <Drawer classes={{ paper: classes.drawerPaper }} variant="permanent" open>
            {drawer({ menuLink })}
          </Drawer>
        </Hidden>
      </nav>
      <Grid className={classes.content}>
        <Hidden smUp>
          <div className={classes.toolbar} />
        </Hidden>
        <Componente useStyles={useStyles} data={data} isLoading={isLoading} contacts={contacts} />
        <BackTop className={classes.backTop} />
        <Footer useStyles={useStyles} Logo={Logo} />
      </Grid>
    </div>
  );
}

export default ResponsiveDrawer;
