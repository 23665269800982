import React from 'react';

import { Link } from 'react-router-dom';

import { Result, Button } from 'antd';

import LayoutConde from '../Layout';

const ResultSuccessComponente = () => {
  return (
    <Result
      status="success"
      title="E-mail enviado con exito."
      subTitle="Pronto atenderemos tu mensaje."
      extra={
        <Link to="/">
          <Button type="primary">Ir a Inicio</Button>
        </Link>
      }
    />
  );
};

const ResultSuccess = () => {
  return <LayoutConde componente={ResultSuccessComponente} />;
};

export default ResultSuccess;
