import React from 'react';

import { Grid, Typography } from '@material-ui/core';

import { LayoutConde, Video } from '../../components';

const HomeComponent = ({ useStyles }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <Typography variant="h2" component="p" className={classes.titleHead}>
        Bienvenido.
      </Typography>
      <Typography variant="body2" component="p" className={classes.description}>
        El Conde te da una cordial bienvenida y nos alegra tu visita, esperamos que puedas encontrar
        lo que necesitas y beneficiarte de nuestros servicios, y que información que hemos preparado
        para ti sea de relevancia y sencilla de consultar.
      </Typography>
      <Typography variant="body2" component="p" className={classes.description}>
        Nuestro objetivo es el ofrecerte un sitio web que represente una experiencia útil de
        investigación relacionada a las tazas de cambio vigentes, y el valor del dolar en la
        actualidad. ¡Échele un vistazo, estamos seguros de que encontrará lo que está buscando!.
      </Typography>
      <Typography variant="body2" component="p" className={classes.description}>
        Como entendemos que amas a tu familia y quieres ayudarlos, nosotros te facilitamos en
        trabajo cuando quieres podras hacerle llegar tu ayuda, solo contactarnos y estaremos allí
        para brindarte el apoyo y la guía que necesitaras..
      </Typography>
      <Video url="https://www.youtube.com/watch?v=D1mZPbGZmNU" />
    </Grid>
  );
};

const Home = () => {
  return <LayoutConde componente={HomeComponent} />;
};

export default Home;
