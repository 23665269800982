import React from 'react';

import { Spin } from 'antd';

import './Loading.scss';

const Loading = ({ size, tip }) => (
  <div className="Loading">
    <Spin size={size || 'large'} tip={tip || 'Cargando...'} />
  </div>
);
export default Loading;
