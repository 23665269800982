import React from 'react';
import ReactDOM from 'react-dom';

import { ThemeProvider } from '@material-ui/core/styles';
import { ConfigProvider } from 'antd';

import es from 'antd/es/locale/es_ES';

import App from './App';
import theme from './themeConfig';

import './index.scss';

// styles antd
import 'antd/dist/antd.css';

// Styles Material
import './resourses/styles/theme.scss';

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <ConfigProvider locale={es}>
      <App />
    </ConfigProvider>
  </ThemeProvider>,
  document.getElementById('root'),
);
