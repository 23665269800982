import React, { useState } from 'react';
import { Tag } from 'antd';
import transaction from '../../resourses/assets/images/money-transaction.png';
import transaction2 from '../../resourses/assets/images/money-transaction2.png';
import tax from '../../resourses/assets/images/tax.png';
import TabsCountrys from './TabsCountrys';

function moneyFormat(moneyCurrency, value) {
  return new Intl.NumberFormat('de-DE', { style: 'currency', currency: moneyCurrency }).format(
    value,
  );
}

const TablePesos = ({ useStyles, dollarVenezuela, dollarColombia }) => {
  const [data] = useState([]);

  const pesos = 10000;
  const bss = 500000;

  const tasaCalculate = dollarColombia / dollarVenezuela;

  const tasaCompra = (7 * tasaCalculate) / 100;

  const tasaVenta = (12.9 * tasaCalculate) / 100;

  const tasaApiVenezuela = tasaCalculate - tasaCompra;

  const tasaApiColombia = tasaCalculate + tasaVenta;

  for (let key = 1; key <= 19; key += 1) {
    let ciclo = key;
    if (ciclo > 10) {
      ciclo = (ciclo - 10 + 1) * 10;
    }

    data.push({
      key,
      quantySendPesos: [pesos * ciclo],
      quantySendBss: [bss * ciclo],
    });
  }

  const titleSend = (
    <div>
      <img alt="transaction" src={transaction} />
      <div>Envias</div>
    </div>
  );
  const titleTasa = (
    <div>
      <img alt="transaction" src={tax} />
      <div>Tasa</div>
    </div>
  );
  const titleReceive = (
    <div>
      <img alt="Bandera Colombia" src={transaction2} />
      <div>Recibes</div>
    </div>
  );


  const columnsOfColombia = [
    {
      dataIndex: 'quantySendPesos',
      title: titleSend,
      width: 100,
      render: (quantySendPesos) => <Tag color="orange">{moneyFormat('COP', quantySendPesos)}</Tag>,
    },
    {
      dataIndex: 'tasaApiColombia',
      title: titleTasa,
      width: 100,
      render: () => <Tag color="blue">{tasaApiColombia.toFixed(4)}</Tag>,
    },
    {
      dataIndex: 'quantySendPesos',
      title: titleReceive,
      width: 100,
      render: (quantySendPesos) => (
        <Tag color="green">{moneyFormat('VES', (quantySendPesos / tasaApiColombia).toFixed())}</Tag>
      ),
    },
  ];

  const columnsOfVenezuela = [
    {
      dataIndex: 'quantySendBss',
      title: titleSend,
      width: 100,
      render: (quantySendBss) => <Tag color="orange">{moneyFormat('VES', quantySendBss)}</Tag>,
    },
    {
      dataIndex: 'tasaApiVenezuela',
      title: titleTasa,
      width: 100,
      render: () => <Tag color="blue">{tasaApiVenezuela.toFixed(4)}</Tag>,
    },
    {
      dataIndex: 'quantySendBss',
      title: titleReceive,
      width: 100,
      render: (quantySendBss) => (
        <Tag color="green">{moneyFormat('COP', (quantySendBss * tasaApiVenezuela).toFixed())}</Tag>
      ),
    },
  ];

  return (
    <TabsCountrys
      columnsOfColombia={columnsOfColombia}
      columnsOfVenezuela={columnsOfVenezuela}
      dataSource={data}
      useStyles={useStyles}
    />
  );
};

export default TablePesos;
