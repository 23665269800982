import React from 'react';

import { Result, Button } from 'antd';

import { Link } from 'react-router-dom';

import LayoutConde from '../Layout';

const Result404Component = () => {
  return <div className="Result">
    <Result
      status="404"
      title="404"
      subTitle="Disculpe, la página que intenta visitar no existe"
      extra={
        <Link to="/">
          <Button type="primary">Ir a Inicio</Button>
        </Link>
      }
    />
  </div>
};

const Result404 = () => {
  return <LayoutConde componente={Result404Component} />;
};

export default Result404;
