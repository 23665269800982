import React from 'react';
import { Empty } from 'antd';

import './EmptyConde.scss';

const EmptyConde = () => {
  return (
    <div className="EmptyConde">
      <Empty description={<span>No hay usuario registrados actualmente...</span>}></Empty>
    </div>
  );
};

export default EmptyConde;
