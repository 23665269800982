import { faWhatsapp, faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons';

// logos bank´s
import banesco from '../resourses/assets/images/bank/banesco.png';
import logoBOD from '../resourses/assets/images/bank/logoBOD.png';
import logoBaloto from '../resourses/assets/images/bank/logoBaloto.png';
import logoEfecty from '../resourses/assets/images/bank/logoEfecty.png';
import logoGanaGana from '../resourses/assets/images/bank/logoGanaGana.png';
import logoMercantil from '../resourses/assets/images/bank/logoMercantil.png';
import logoPagaTodo from '../resourses/assets/images/bank/logoPagaTodo.png';
import logoProvincial from '../resourses/assets/images/bank/logoProvincial.png';
import logoSuperGiros from '../resourses/assets/images/bank/logoSuperGiros.png';
import logoVenezuela from '../resourses/assets/images/bank/logoVenezuela.png';

const dataMenuLinks = [
  { id: 0, link: '/login', text: 'Login' },
  { id: 1, link: '/home', text: 'Inicio' },
  { id: 2, link: '/table', text: 'Tabla de cambios' },
  { id: 3, link: '/entidades', text: 'Entidades' },
  { id: 4, link: '/about', text: 'Acerca de nosotros' },
  { id: 5, link: '/contact', text: 'Contáctenos' },
  { id: 6, link: '/clients', text: 'Clientes' },
];

const bancosVenezuela = [
  {
    id: 1,
    name: 'Banco Occidental de Descuento',
    img: logoBOD,
    url: 'https://web.bancadigitalbod.com/nblee6/f/ext/Login/index.xhtml',
  },
  {
    id: 2,
    name: 'Banesco',
    img: banesco,
    url: 'https://www.banesconline.com/mantis/Website/Login.aspx',
  },
  {
    id: 3,
    name: 'Mercantil',
    img: logoMercantil,
    url: 'https://www30.mercantilbanco.com/olb/InitMerc',
  },
  {
    id: 4,
    name: 'Provincial',
    img: logoProvincial,
    url: 'https://www.bbvaprovinet.provincial.com/personas/BBVAProvincial.html',
  },
  {
    id: 5,
    name: 'Banco de Venezuela',
    img: logoVenezuela,
    url: 'https://bdvenlinea.banvenez.com/',
  },
];
const bancosColombia = [
  {
    id: 1,
    name: 'Baloto',
    img: logoBaloto,
    url: 'https://www.baloto.com/',
  },
  {
    id: 2,
    name: 'Paga Todo',
    img: logoPagaTodo,
    url: 'https://www.pagatodo.com.co/',
  },
  {
    id: 3,
    name: 'Efecty',
    img: logoEfecty,
    url: 'https://www.efecty.com.co/',
  },
  {
    id: 4,
    name: 'Gana Gana',
    img: logoGanaGana,
    url: 'http://www.ganagana.com.co/',
  },
  {
    id: 5,
    name: 'Super Giros',
    img: logoSuperGiros,
    url: 'https://www.supergiros.com.co/',
  },
];
const socialMedia = [
  {
    id: 1,
    href:
      'https://web.whatsapp.com/send?text=Usa%20esta%20web%20para%20ver%20el%20precio%20del%20d%C3%B3lar%20actual%20y%20tablas%20de%20transferencias%20en%20pesos%20a%20bol%C3%ADvares%2C%20y%20mucho%20m%C3%A1s%20https%3A%2F%2Felconde.now.sh%2Ftable',
    icon: faWhatsapp,
    color: '#51c85d',
    size: '2x',
  },
  {
    id: 2,
    href:
      'https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Felconde.now.sh%2Ftable%20Usa%20esta%20web%20para%20ver%20el%20precio%20del%20d%C3%B3lar%20actual%20y%20tablas%20de%20transferencias%20en%20pesos%20a%20bol%C3%ADvares%2C%20y%20mucho%20m%C3%A1s%20',
    icon: faFacebook,
    color: '#4968ad',
    size: '2x',
  },
  {
    id: 3,
    href:
      'https://twitter.com/intent/tweet?url=https%3A%2F%2Felconde.now.sh%2Ftable%20Usa%20esta%20web%20para%20ver%20el%20precio%20del%20d%C3%B3lar%20actual%20y%20tablas%20de%20transferencias%20en%20pesos%20a%20bol%C3%ADvares%2C%20y%20mucho%20m%C3%A1s%20',
    icon: faTwitter,
    size: '2x',
    color: '#49a1eb',
  },
];

export { dataMenuLinks, bancosVenezuela, bancosColombia, socialMedia };
