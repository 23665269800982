import React from 'react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { Home, Contact, About, Tabla, Entidades, Client, Log } from './scenes';

import ResultSuccess from './components/Result/ResutlSuccess';
import Result404 from './components/Result/Resultt404';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/login" component={Log} exact />
        <Route path="/home" component={Home} exact />
        <Route path="/table" component={Tabla} exact />
        <Route path="/entidades" component={Entidades} exact />
        <Route path="/about" component={About} exact />
        <Route path="/clients" component={Client} exact />
        <Route path="/contact" component={Contact} exact />
        <Route path="/success" component={() => <ResultSuccess />} exact />
        <Route component={() => <Result404 />} />
      </Switch>
    </Router>
  );
}

export default App;
