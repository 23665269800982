import React from 'react';

import { Modal, Button, Space } from 'antd';

const ReachableContext = React.createContext();

const config = {
  title: 'Clausula!',
  content: (
    <div>
      <ReachableContext.Consumer>{(name) => `1:TEST ${name}!`}</ReachableContext.Consumer>
    </div>
  ),
};

const Clausula = () => {
  const [modal, contextHolder] = Modal.useModal();

  return (
    <ReachableContext.Provider value="Light">
      <Space>
        <Button
          onClick={() => {
            modal.info(config);
          }}
          type="link"
          block
        >
          Cláusula
        </Button>
      </Space>
      {contextHolder}
    </ReachableContext.Provider>
  );
};

export default Clausula;
