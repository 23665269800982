import React from 'react';

import { Grid } from '@material-ui/core/';

import SocialFollow from './SocialFollow';

const Footer = (props) => {
  const { useStyles } = props;

  return (
    <Grid container spacing={2}>
      <SocialFollow useStyles={useStyles} />
    </Grid>
  );
};
export default Footer;
