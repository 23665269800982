import React from 'react';

import { ReactComponent as ImageClients } from '../../resourses/assets/svg/imageClients.svg';
import { ReactComponent as ImageContact } from '../../resourses/assets/svg/imageContact.svg';
import { ReactComponent as ImageEntidad } from '../../resourses/assets/svg/imageEntidad.svg';
import { ReactComponent as ImageTableChanges } from '../../resourses/assets/svg/imageTableChanges.svg';
import { ReactComponent as EmptyConde } from '../../resourses/assets/svg/empty.svg';

const BackgroundClients = ({ className }) => <ImageClients className={className} />;
const BackgroundContact = ({ className }) => <ImageContact className={className} />;
const BackgroundEntidad = ({ className }) => <ImageEntidad className={className} />;
const BackgroundTableChanges = ({ className }) => <ImageTableChanges className={className} />;
const BackgroundEmptyConde = ({ className }) => <EmptyConde className={className} />;

export {
  BackgroundClients,
  BackgroundEntidad,
  BackgroundContact,
  BackgroundTableChanges,
  BackgroundEmptyConde,
};
