import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { Grid, Avatar, Typography } from '@material-ui/core';
import { getContact } from '../../services';

import { LayoutConde, Loading, EmptyConde } from '../../components';

import {
  BackgroundClients,
  BackgroundEmptyConde,
} from '../../components/BackGroundMultimedia/index';

import banVen from '../../resourses/assets/images/banVen.png';
import banCol from '../../resourses/assets/images/banCol.png';

const ClientsComponent = ({ useStyles, isLoading, contacts = [] }) => {
  const classes = useStyles();
  const cardGeretatorContacts = contacts.map((item) => {
    return (
      <Grid container item xs={12} sm={6} key={item.id}>
        <Grid item xs className={classes.boxClients}>
          <Avatar alt={item.name} src={item.imgUrl} className={classes.avatar} />
          <Grid item xs container direction="row" spacing={2}>
            <Grid item xs>
              <Typography variant="h6" className={classes.namesClients}>
                {item.name} {item.lastName}
              </Typography>
              <Typography variant="body2" component="p" className={classes.description}>
                <Typography variant="body2" component="p">
                  Email: {item.email}
                </Typography>
                <Typography variant="body2" component="p">
                  Telefóno: {item.phone}
                </Typography>
                {item.country === 'Venezuela' ? (
                  <Typography variant="body2" component="p">
                    Pais: {item.country}
                    <img alt="Bandera Venezuela" src={banVen} />
                  </Typography>
                ) : (
                  <Typography>
                    Pais: {item.country}
                    <img alt="Bandera Colombia" src={banCol} />
                  </Typography>
                )}
              </Typography>
              <Typography>Opinión: {item.message}</Typography>
              <Typography variant="body2" component="p" className={classes.subTitle}>
                Fecha: {moment(item.createdAt).format('DD/MM/YYYY - HH:MM:SS A')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  });

  return (
    <Grid container className={classes.root}>
      {!contacts.length ? (
        <BackgroundEmptyConde className={classes.backgroundmultimedia} />
      ) : (
        <BackgroundClients className={classes.backgroundmultimedia} />
      )}
      <Typography variant="h2" component="p" className={classes.titleHead}>
        Clientes
      </Typography>
      <Typography variant="body2" component="p" className={classes.description}>
        Estos son algunos testimonios de nuestros clientes, por que sabemos que tus opiniones nos
        ayudaran a ser mejores cada dia.
      </Typography>
      {isLoading && <Loading tip="Cargando a los clientes, espero por favor..." />}
      {!contacts.length ? <EmptyConde /> : cardGeretatorContacts}
    </Grid>
  );
};

const Client = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [contacts, setContacts] = useState([]);

  async function loadContact() {
    const response = await getContact();
    console.log(response);
    if (response) {
      setContacts(response.data);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    loadContact();
  }, []);

  return <LayoutConde isLoading={isLoading} contacts={contacts} componente={ClientsComponent} />;
};

export default Client;
